$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;

.Projects {
    padding: 80px 60px 0px;
    position: relative;
    background-image: url('../../utilities/images/ProjectBG2.svg'), url('../../utilities/images/ProjectBG1.svg');
    background-position-y: 200px, 100%;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    &__palettes {
        height: 48px;
        width: 268px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
        left: 0;
        top: 0; }
    &__palette {
        width: 40px;
        height: 40px;
        border: 1px solid var(--black);
        cursor: pointer;
        &--1 {
            background: var(--white); }
        &--2 {
            background: var(--c1); }
        &--3 {
            background: var(--c2); }
        &--4 {
            background: var(--c3); }
        &--5 {
            background: var(--c4); }
        &--6 {
            background: var(--black); } }
    &__items {
        margin-top: 60px; } }

/*----------------------------------Media Queries----------------------------------*/
@media only screen and (max-width: $layout-breakpoint-xl) {
    .Projects {
        padding: 80px 30px 0px; } }

@media only screen and (max-width: $layout-breakpoint-xl) {
    .Projects {
        background-position-y: 250px, 90%;
        background-size: 100% 33%; } }

@media only screen and (max-width: $layout-breakpoint-xs) {
    .Projects {
        padding: 80px 10px 0px; } }
