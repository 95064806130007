@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --white: #FFFFFF;
  --black: #000000;
  --c1: #F5C7F7;
  --c2: #A393EB;
  --c3: #5E63B6;
  --c4: #27296D;
}

* {
  box-sizing: border-box;
}

.App {
  height: max-content;
  width: 100%;
  text-align: center;
  /* overflow-x: hidden; */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--c2);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--c3);
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Montserrat, sans-serif;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
}

ul {
  margin: 0;
  padding: 0;
}

.titleHolder {
  margin: auto;
  color: var(--c3);
  font-size: 35px;
  font-weight: 700;
}

.line {
  content: '';
  height: 4px;
  width: 200px;
  margin: 5px auto;
  background: var(--blue3);
}

.lCircle {
  content: '';
  height: 10px;
  width: 10px;
  background: var(--blue3);
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 450px) {
  .titleHolder {
    margin: 0px auto 0;
    font-size: 1.7rem;
  }
  .titleHolder::before, .titleHolder::after {
    content: '';
    width: 25vw;
  }
}