$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;
$layout-breakpoint-xxl: 1440px;

.Project {
    display: flex;
    align-items: flex-end;
    margin-bottom: 80px;
    &__container {
        width: 60%;
        height: 428px;
        padding: 50px 60px;
        background: var(--white);
        box-shadow: 0px 4px 10px 1px rgba(94, 99, 182, 0.15);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start; }
    &__title {
        font-size: 2.5rem;
        color: var(--c3);
        text-transform: uppercase; }
    &__desc {
        color: var(--c3);
        text-align: left;
        font-size: 1.1rem;
        width: 80%; }
    &__link--image {
        background: var(--c2);
        padding: 10px 15px;
        border-radius: 10px; }
    &__liveLink {
        margin-left: 24px; }
    &__image {
        width: 430px;
        height: 370px;
        border: 1px solid var(--c4);
        border-radius: 20px;
        margin-left: -172px;
        transform: translate3d(0, 40px, 0); }
    &__right {
        justify-content: flex-end;
        .Project {
            &__image {
                margin-right: -172px; }
            &__container {
                order: 1;
                padding-right: 0px;
                padding-left: 210px; } } } }

/*----------------------------------Media Queries----------------------------------*/
@media only screen and (max-width: $layout-breakpoint-xxl) {
    .Project {
        &__container {
            width: 73.5%; } } }

@media only screen and (max-width: $layout-breakpoint-lg) {
    .Project {
        &__container {
            height: 350px;
            width: 75%;
            padding: 30px; }
        &__title {
            font-size: 1.75rem; }
        &__desc {
            font-size: 0.9rem;
            width: 65%; }
        &__image {
            width: 350px;
            height: 300px; }
        &__link--image {
            padding: 8px 12px;
            height: 38px; } } }

@media only screen and (max-width: $layout-breakpoint-md) {
    .Project {
        margin-top: 300px;
        position: relative;
        justify-content: flex-end;
        &__container {
            height: 300px;
            width: 100%; }
        &__title {
            font-size: 2rem; }
        &__desc {
            width: 100%; }
        &__image {
            width: 300px;
            height: 250px;
            margin: 0;
            position: absolute;
            top: -80%;
            left: 0;
            transform: translate3d(0%, 0%, 0); }
        &__right {
            .Project {
                &__container {
                    padding: 30px; } } } } }

@media only screen and (max-width: $layout-breakpoint-sm) {
    .Project {
        &__container {
            height: 250px;
            align-items: center; }
        &__desc {
            text-align: center; }
        &__image {
            top: -95%;
            left: 50%;
            transform: translate3d(-50%, 0, 0); }
        &__link--image {
            padding: 8px 12px;
            height: 35px; } } }

@media only screen and (max-width: $layout-breakpoint-xs) {
    .Project {
        &__title {
            font-size: 1.3rem; }
        &__image {
            width: 300px;
            height: 220px;
            top: -85%; }
        &__desc {
            font-size: 0.8rem; }
        &__link--image {
            padding: 8px 8px;
            height: 32px; } } }
