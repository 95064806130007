$layout-breakpoint-md: 768px;

.Nav {
    position: fixed;
    top: 16px;
    right: 30px;
    height: 48px;
    width: 48px;
    background: var(--white);
    border-radius: 24px;
    box-shadow: 0px 0px 4px 2px rgba(94, 99, 182, 0.25);
    z-index: 999;
    &__items {
        list-style: none;
        display: flex;
        position: absolute;
        right: 48px;
        top: 50%;
        transform: translate3d(0, -50%, 0); }
    &__item {
        margin-right: 20px;
        font-size: 16px;
        color: var(--c3);
        font-weight: 700;
        cursor: pointer;
        &::before {
            content: '';
            height: 2px;
            width: 100%;
            background: var(--c3);
            transform: scale3d(0, 1, 1);
            position: absolute;
            bottom: -2px;
            transition: 300ms; }
        &--active::before {
            transform: scale3d(1, 1, 1); } }
    &__ham {
        height: 48px;
        width: 48px;
        padding: 16px 12px;
        float: right;
        cursor: pointer;
        &--item {
            background: var(--c3);
            height: 3px;
            width: 24px;
            position: absolute;
            top: 50%;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:nth-child(1) {
                transform: translate3d(0, -350%, 0); }
            &:nth-child(2) {
                transform: translate3d(0, -50%, 0); }
            &:nth-child(3) {
                transform: translate3d(0, 250%, 0); } } }
    &__checkbox {
        display: none;
        &:checked {
            & ~ .Nav__ham {
                & > .Nav__ham--item {
                    &:nth-child(1) {
                        transform: translate3d(0, -50%, 0) rotate(45deg); }
                    &:nth-child(2) {
                        transform: translate3d(0, -50%, 0) scale(0.1, 1); }
                    &:nth-child(3) {
                        transform: translate3d(0, -50%, 0) rotate(-45deg); } } } } } }

/*----------------------------------Media Queries----------------------------------*/
@media only screen and (max-width: $layout-breakpoint-md) {
    .Nav {
        top: unset;
        right: 16px;
        bottom: 16px;
        &__items {
            flex-direction: column;
            right: 10px;
            transform: translate3d(0, -100%, 0); }
        &__item {
            margin-bottom: 5vh;
            margin-right: 0px;
            color: var(--white);
            background: linear-gradient(104.64deg, #5E63B6 32.09%, #27296D 100%);
            box-shadow: 0px 0px 4px 2px rgba(94, 99, 182, 0.25);
            padding: 10px;
            border-radius: 10px;
            font-size: 1.1rem;
            &::before {
                width: 30%;
                bottom: 6px;
                left: 50%;
                background: var(--white); }
            &--active::before {
                transform: translate3d(-50%, 0, 0); } } } }
