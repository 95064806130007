$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;

.Education {
    position: relative;
    width: 1000px;
    max-width: 100%;
    padding-top: 80px;
    margin: auto;
    overflow: hidden;
    &__item {
        clear: both; }
    &__timeline {
        margin: 60px auto;
        padding-bottom: 2px;
        width: 80%;
        min-width: 720px;
        position: relative;
        &::before {
            content: '';
            width: 3px;
            height: 100%;
            background: var(--c2);
            position: absolute;
            left: 50%;
            transform: translateX(-50%); }
        &::after {
            content: '';
            height: 3px;
            width: 250px;
            background: var(--c2);
            position: absolute;
            bottom: 0;
            transform: translateX(-50%); }
        &--duration {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; }
        &--stat {
            height: 130px;
            width: 45%;
            margin-bottom: 50px;
            border-radius: 5px;
            color: var(--c4);
            background: var(--white);
            box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
            &.right {
                float: right;
                & > .institute::after {
                    right: unset;
                    left: -2%;
                    border-left: 0;
                    border-right: 10px solid var(--c2); } } } } }

.institute {
    color: var(--white);
    background: var(--c2);
    padding: 15px 8px;
    position: relative;
    border-radius: 5px 5px 0 0;
    &::after {
        content: '';
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid var(--c2);
        position: absolute;
        right: -2%;
        top: 50%;
        transform: translateY(-50%); } }

.duration {
    margin-top: 10px;
    font-weight: 600; }

.performance {
    margin-top: 10px; }

.icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    background: var(--c2);
    transform: translateX(-50%);
    & > img {
        height: 25px;
        margin-top: 11px; } }

@media only screen and (max-width: $layout-breakpoint-md) {
    .icon {
        position: static;
        display: inline;
        float: left; }
    .Education {
        width: 100%;
        padding-top: 10px;
        &__timeline {
            min-width: 320px;
            width: 70%;
            transform: translate3d(12.5px, 0, 0);
            &::before {
                left: 0; }
            &::after {
                left: 50px;
                width: 100px; }
            &--stat {
                margin-left: 40px;
                width: 100%;
                min-width: 280px;
                & > .institute::after {
                    right: unset;
                    left: -2%;
                    border-left: 0;
                    border-right: 10px solid var(--c2); }
                &.right {
                    float: unset; } } }
        &__item {
            width: 90%; } } }

@media only screen and (max-width: $layout-breakpoint-sm) {
    .Education__timeline {
        width: 400px; } }

@media only screen and (max-width: $layout-breakpoint-xs) {
    .Education {
        &__timeline {
            width: 250px;
            min-width: 280px;
            font-size: 0.8rem;
            transform: translate3d(10px, 0, 0);
            &--stat {
                min-width: unset;
                margin-left: 30px; } } }
    .icon {
        height: 40px;
        width: 40px;
        & > img {
            height: 20px;
            margin-top: 9px; } } }
