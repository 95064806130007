$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;

.Footer {
    background: var(--c2);
    color: var(--white);
    height: 80px;
    padding: 0px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

@media only screen and (max-width: $layout-breakpoint-md) {
    .Footer {
        font-size: 1.2rem;
        justify-content: center; } }
