$layout-breakpoint-md: 768px;

.Notification {
    position: fixed;
    bottom: 20px;
    height: 60px;
    border: 1px solid var(--c2);
    border-radius: 10px;
    z-index: 99;
    background: var(--white);
    color: var(--c3);
    overflow: hidden;
    font-size: 0.8rem;
    > div {
        height: 70%;
        padding: 13px 10px 0; }
    &::before {
        content: '';
        background: var(--c3);
        display: block;
        width: 100%;
        height: 30%; } }

@media only screen and (max-width: $layout-breakpoint-md) {
    .Notification {
        top: 20px; } }
