$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;

.Hire {
    color: var(--white);
    background: var(--c2);
    width: 500px;
    margin: 18px auto 72px;
    border-radius: 20px;
    text-align: left;
    font-size: 1.1rem;
    padding: 15px 20px;
    box-shadow: 0px 20px 0px rgba(39, 41, 109, 0.7);
    &__title {
        font-weight: 700; }
    &__label {
        display: block;
        margin: 30px auto 5px; }
    &__input {
        height: 30px;
        width: 100%;
        border: none;
        border-radius: 5px;
        outline: none;
        font-size: 1.1rem; }
    &__btn {
        margin: 15px auto 0px;
        display: block;
        padding: 5px 20px;
        border: none;
        border-radius: 15px;
        color: var(--c3);
        background: var(--white);
        font-weight: 800;
        font-size: 1.1rem;
        cursor: pointer;
        outline: none; } }

#message {
    height: 200px; }

@media only screen and (max-width: $layout-breakpoint-sm) {
    .Hire {
        width: 90%;
        padding: 15px;
        font-size: 1rem;
        &__label {
            margin-top: 15px; }
        &__input {
            height: initial; } } }
