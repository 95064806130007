$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;

.Experiences {
    padding-top: 80px;
    &__bg {
        height: 350px;
        width: 90%;
        margin: 60px auto 0;
        background: rgba(163, 147, 235, 0.3);
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center; } }

@media only screen and (max-width: $layout-breakpoint-lg) {
    .Experiences {
        font-size: 0.9rem; } }

@media only screen and (max-width: $layout-breakpoint-md) {
    .Experiences {
        padding-top: 10px;
        &__bg {
            width: 100%;
            flex-direction: column;
            margin: 200px auto 150px; } } }
