$layout-breakpoint-xs: 480px;
$layout-breakpoint-sm: 600px;
$layout-breakpoint-md: 768px;
$layout-breakpoint-lg: 1024px;
$layout-breakpoint-xl: 1200px;

.Skill {
    color: var(--c4);
    width: 90%;
    max-width: 320px;
    height: 490px;
    padding: 20px 10px;
    word-spacing: 0.5px;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0px 0px 20px 0px rgba(94, 99, 182, 0.25);
    will-change: transform;
    &:hover {
        box-shadow: 0px 0px 50px 10px rgba(94, 99, 182, 0.25); }
    &__icon {
        width: 60%;
        margin-bottom: 10px;
        &--bg {
            content: '';
            padding: 10px;
            width: 50%;
            margin: auto;
            background: radial-gradient(50% 50% at 50% 50%, rgba(163, 147, 235, 0.35) 0%, rgba(163, 147, 235, 0) 100%); } }
    &__desc {
        padding: 0px 15px;
        background: var(--blue0);
        &--container {
            height: 155px;
            display: flex;
            align-items: center; } } }

/*----------------------------------Media Queries----------------------------------*/
@media only screen and (max-width: $layout-breakpoint-xl) {
    .Skill {
        width: 30%;
        &__desc {
            padding: 0px; } } }

@media only screen and (max-width: $layout-breakpoint-lg) {
    .Skill {
        height: 460px;
        &__desc {
            font-size: 0.9rem; } } }

@media only screen and (max-width: $layout-breakpoint-md) {
    .Skill {
        width: 80%;
        padding: 20px; } }
